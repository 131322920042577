<template>
  <div class="detail-charts relative">
    <div class="charts-menu flex h-60 items-center justify-between">
      <div class="flex w-full items-center justify-between">
        <!-- 先隐藏，等后端补一些数据以后再放出来 -->
        <div class="filters flex items-center">
          <el-select v-model="filterData.platform" value-key="value" class="platform-select ml-10 mr-20 w-100" @change="changeTypePlatform">
            <el-option v-for="(platform, index) in platformList" :key="index" :value="platform" :label="platform.text" border
              ><i :class="['iconfont', `icon-${platform.value.toLowerCase()}`]"></i> {{ platform.text }}</el-option
            >
            <template #prefix> <i :class="['iconfont', `icon-${filterData.platform.value.toLowerCase()}`]"></i></template>
          </el-select>
          <el-tabs v-model="filterData.showType" tab-position="top" class="chart-types" @tab-change="changeType">
            <el-tab-pane :label="$t('走势图')" :name="'charts'"> </el-tab-pane>
            <el-tab-pane :label="$t('K线图')" :name="'klinecharts'"> </el-tab-pane>
          </el-tabs>
        </div>

        <template v-if="filterData.showType === 'charts'">
          <div class="mr-30">
            <el-tree-select v-model="selecetdKeys" :data="baseKeyList" multiple show-checkbox collapse-tags :render-after-expand="false" style="width: 200px" @change="keyChange" />
            <el-select v-model="filterData.typeDay" class="ml-40 w-100" :value-key="'value'" style="width: 100px" @change="changeTypeDay">
              <el-option v-for="(day, index) in dayTypeList" :key="index" :value="day" :label="day.text" border>{{ day.text }}</el-option>
            </el-select>
          </div>
        </template>
      </div>
    </div>

    <div v-if="loading" class="bg-white">
      <CommonLoading />
    </div>

    <div v-else class="charts-area rounded-[10px] py-20">
      <div :class="filterData.showType === 'charts' ? '' : 'hidden'">
        <div id="J_Echart" :style="{ width, height }"></div>
      </div>
      <div :class="filterData.showType === 'klinecharts' ? '' : 'hidden'">
        <div id="J_Klinechart" :style="{ width, height }" @click="klineClick"></div>
      </div>
      <div class="mt-10 pl-44 text-red">
        {{ $t('当前走势数据为收藏夹全量饰品整体走势，当收藏夹内饰品存在更新时，会清空走势数据并重新生成近3月数据,请谨慎操作。') }}
      </div>
    </div>
  </div>
</template>

<script setup>
import * as echarts from 'echarts/core'
import { ref, reactive, onMounted } from 'vue'
import { TooltipComponent, GridComponent, DataZoomComponent, LegendComponent } from 'echarts/components'
import { LineChart, BarChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import { KLineChartPro } from '@klinecharts/pro'
import { CustomDatafeed } from '~/api/collectfolderDatafeed'
import dayjs from 'dayjs'

import { collectFolderTrend } from '~/api/collect'

import '@klinecharts/pro/dist/klinecharts-pro.css'

const { t } = useI18n()
const $t = t

const emit = defineEmits(['showDetails'])
let myChart = null
const klineStorage = useLocalStorage('detail-kline')
const props = defineProps({
  tendData: {
    type: Object,
    default: () => {}
  },
  submitParam: {
    type: Object,
    default: () => {}
  },
  defaultFilter: {
    type: Object,
    default: () => {
      return {
        platform: 'ALL',
        typeDay: '1'
      }
    }
  },
  folder: {
    type: Object,
    default: () => {}
  },
  width: {
    type: String,
    default: '1360px'
  },
  height: {
    type: String,
    default: '500px'
  },
  usedBy: {
    type: String,
    default: ''
  },
  detail: {
    type: Object,
    default: () => {}
  },
  echartParam: {
    type: Object,
    default: () => {}
  }
})
console.log('props.dafaultFilter', props.defaultFilter)
const chartStorage = useLocalStorage('detail-chart')
const keyStorage = useLocalStorage('detail-chart-key')
const tempChartObj = ref({})
console.log('keyStorage', keyStorage.value)
const selecetdKeys = ref(keyStorage.value && keyStorage.value.length > 0 ? JSON.parse(keyStorage.value) : ['sellPrice', 'sellCount', 'dayTransactionCount'])
const baseKeyList = [
  {
    value: 'onsale',
    label: $t('在售'),
    children: [
      {
        value: 'sellPrice',
        label: $t('在售价')
      },
      {
        value: 'sellCount',
        label: $t('在售数')
      }
    ]
  },
  {
    value: 'bidding',
    label: $t('求购'),
    children: [
      {
        value: 'biddingPrice',
        label: $t('求购价')
      },
      {
        value: 'biddingCount',
        label: $t('求购数')
      }
    ]
  },
  {
    value: 'transaction',
    label: $t('成交'),
    children: [
      {
        value: 'transactionCount',
        label: $t('小时成交量')
      },
      {
        value: 'dayTransactionCount',
        label: $t('天成交量')
      }
    ]
  }
]

const platformList = ref([
  {
    text: 'BUFF',
    value: 'BUFF'
  },
  {
    text: $t('悠悠'),
    value: 'YOUPIN'
  },
  {
    text: 'C5GAME',
    value: 'C5'
  },
  {
    text: 'Steam',
    value: 'STEAM'
  }
])
const dayTypeList = ref([
  {
    text: $t('近1个月'),
    value: '1'
  },
  {
    text: $t('近3个月'),
    value: '2'
  },
  {
    text: $t('近6个月'),
    value: '3'
  },
  {
    text: $t('近1年'),
    value: '4'
  },
  {
    text: $t('近3年'),
    value: '5'
  }
])

if (!chartStorage.value) {
} else {
  tempChartObj.value = JSON.parse(chartStorage.value)
}
const filterData = reactive(
  Object.assign(
    {},
    { charts: [] },
    {
      platform: {
        value: props.defaultFilter.platform || tempChartObj.value?.platform,
        text: platformList.value.find(item => item.value === props.defaultFilter.platform || tempChartObj.value?.platform).text
      },
      typeDay: {
        value: props.defaultFilter.typeDay || tempChartObj.value?.typeDay?.value,
        text: dayTypeList.value.find(item => item.value === props.defaultFilter.typeDay).text || tempChartObj.value?.typeDay?.text
      },
      showType: props.defaultFilter.showType || tempChartObj.value?.showType
    }
  )
)
const loading = ref(false)

watch(filterData, (newFilter, oldFilter) => {
  console.log('newFilter', newFilter)

  tempChartObj.value.platform = newFilter.platform.value
  tempChartObj.value.typeDay = newFilter.typeDay
  tempChartObj.value.showType = newFilter.showType
  chartStorage.value = JSON.stringify(tempChartObj.value)
})

watch(selecetdKeys, (newKeys, oldKeys) => {
  console.log('newKeys', newKeys)
  if (newKeys.length === 0) {
    newKeys = ['sellPrice', 'sellCount', 'dayTransactionCount']
  }
  keyStorage.value = JSON.stringify(newKeys)
})
const beforeDomWidth = ref(0)
onMounted(() => {
  if (props.tendData) {
    handleTendData(props.tendData)
  } else {
    getTendData()
  }
  const resizeObserver = new ResizeObserver(entries => {
    // 处理大小变化的回调函数
    for (const entry of entries) {
      console.log('元素大小已变化', entry.target.offsetWidth)
      // 在这里执行你想要的操作，比如更新数据或触发其他事件
      // changeTypePlatform()
      if (beforeDomWidth.value && beforeDomWidth.value !== entry.target.offsetWidth && (entry.target.offsetWidth === 904 || entry.target.offsetWidth === 1400)) {
        console.log('beforeDomWidth', beforeDomWidth.value)
        changeTypePlatform()
      }
      beforeDomWidth.value = entry.target.offsetWidth
    }
  })
  nextTick(() => {
    resizeObserver.observe(document.querySelector('.detail-charts'))
  })

  // setTimeout(() => {
  //   echartInit()
  // }, 0)
})

const keyChange = () => {
  echartInit()
}

const echartInit = () => {
  console.log('echartInit')

  const { charts } = filterData

  echarts.use([TooltipComponent, GridComponent, DataZoomComponent, LegendComponent, LineChart, BarChart, UniversalTransition, CanvasRenderer, SVGRenderer])
  const chartDom = document.getElementById('J_Echart')
  if (myChart) {
    myChart.dispose()
  }
  myChart = echarts.init(chartDom, null, {
    renderer: 'svg'
  })
  const option = {
    grid: {
      bottom: '80px',
      left: '65px',
      right: '65px',
      containLabel: false
    },
    color: [],
    tooltip: {
      show: true,
      trigger: 'axis',
      axisPointer: { type: 'cross' },
      formatter: function (params) {
        if (params.length > 0) {
          const curParams = params[0]
          const curData = curParams.data
          const curDate = curParams.axisValue
          let str = `${curDate}<br>`

          params.forEach(item => {
            const curItem = item.data
            const curKey = item.seriesName
            const curValue = curItem[curKey]

            str += `<div><div style="display:flex;justify-content: space-between"><div>${item.marker} ${item.seriesName}</div> <div style="text-align: right;">${item.value}</div></div>`
          })
          //  if (props.usedBy !== 'category') {
          //    str += `<div style='color: #777; font-size: 12px;margin-top: 5px'>${$t('提示：走势图成交量柱状图可点击')}</div>`
          //  }
          // str += `<div style='color: #777; font-size: 12px;margin-top: 5px'>${$t('提示：走势图成交量柱状图可点击')}</div>`

          return str
        }
      }
    },
    legend: {
      inactiveColor: '#979CAE',
      itemGap: 20,
      itemWidth: 18,
      textStyle: {
        fontSize: 16,
        color: 'auto',
        padding: [0, 5]
      },
      lineStyle: {
        inactiveWidth: 1
      },
      data: [],
      selected: {}
    },
    textStyle: {
      fontSize: 14,
      color: '#979CAE'
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        axisLabel: {
          formatter: function (val) {
            return dayjs(new Date(val).getTime()).format('MM-DD')
          }
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: $t('金额（¥）'),
        min: function (value) {
          const difference = (value.max - value.min) * 0.1
          return value.min - difference <= 0 ? 0 : (value.min - difference).toFixed(2)
        },
        max: function (value) {
          const difference = (value.max - value.min) * 0.1
          return (value.max + difference).toFixed(2)
        },
        position: 'left',
        boundaryGap: [0, '100%'],
        splitLine: {
          lineStyle: {
            width: 1,
            color: '#EFF1F5'
          }
        }
      },
      {
        type: 'value',
        name: $t('在售数量（件）'),
        min: function (value) {
          const difference = (value.max - value.min) * 0.1
          return value.min - difference <= 0 ? 0 : Math.ceil(value.min - difference)
        },
        max: function (value) {
          const difference = (value.max - value.min) * 0.1
          return Math.ceil(value.max + difference)
        },
        position: 'right',
        boundaryGap: [0, '100%']
      },
      {
        show: false,
        type: 'value',
        name: $t('求购数量（件）'),
        min: 'dataMin',
        max: 'dataMax',
        position: 'right'
        // boundaryGap: [0, '33%']
      },
      {
        show: false,
        type: 'value',
        name: $t('成交数量（件）'),
        min: 'dataMin',
        max: function (value) {
          return value.max * 5
        },
        position: 'right'
        // boundaryGap: [0, '33%']
      },
      {
        show: false,
        type: 'value',
        name: $t('每日成交数量'),
        min: function (value) {
          return value.min / 3 <= 0 ? 0 : value.min / 3
          // return value.min
        },
        max: function (value) {
          return value.max * 4
        },
        position: 'right'
      }
    ],
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
        // zoomLock: true,
        zoomOnMouseWheel: true
      },
      {
        start: 0,
        end: 100,
        height: 50,
        bottom: 0,
        backgroundColor: '#F5F8FD',
        borderColor: '#EFF1F5',
        borderRadius: 0,
        dataBackground: {
          lineStyle: {
            width: 1,
            color: '#0252D9'
          },
          areaStyle: {
            color: '#EBEEFD',
            opacity: 1
          }
        },
        selectedDataBackground: {
          lineStyle: {
            width: 1,
            color: '#0252D9'
          },
          areaStyle: {
            color: '#EBEEFD',
            opacity: 1
          }
        },
        fillerColor: 'rgba(2, 82, 217, 0.2)',
        textStyle: {
          fontSize: 12,
          color: '#979CAE'
        },
        labelFormatter: function (val) {
          // return dayjs(val).format('YY-MM-DD HH:mm:ss')
        },
        handleIcon: 'image://https://steamdt.cn/sliderBar.svg',
        moveHandleSize: 0
      }
    ],
    series: []
  }

  for (let key in charts) {
    let legend_item = {}
    let series_item = {}
    let maxDayTransactionCount = 0

    if (key === 'dayTransactionCount') {
      // 合并同一天的数据
      let timeList = {}
      let dayTransactionCount = []
      let curIndex = 0

      charts[key].datelist.forEach((item, index) => {
        const curDate = dayjs(item * 1000).format('YYYY-MM-DD')

        if (timeList[curDate]) {
          timeList[curDate].length += 1
          timeList[curDate].count += charts[key].ylist[index]
          curIndex++
          dayTransactionCount.push({
            curDate: curDate,
            curTime: dayjs(item * 1000).format('HH'),
            groupId: curDate,
            index: curIndex
          })
        } else {
          timeList[curDate] = {
            index: 0,
            length: 1,
            count: 0
          }
          timeList[curDate].count = charts[key].ylist[index]

          curIndex = 0
          dayTransactionCount.push({
            curDate: curDate,
            curTime: dayjs(item * 1000).format('HH'),
            groupId: curDate,
            index: curIndex
          })
        }
      })

      dayTransactionCount.forEach(item => {
        const key = item.curDate

        item.value = timeList[key].count
        item.size = timeList[key].length

        maxDayTransactionCount = maxDayTransactionCount > timeList[key].count ? maxDayTransactionCount : timeList[key].count
      })

      // for (let key in timeList) {
      //   for (let i = 0; i < timeList[key].length; i++) {
      //     dayTransactionCount.push({
      //       curDate: key,
      //       value: timeList[key].count,
      //       groupId: key,
      //       size: timeList[key].length,
      //       index: i
      //     })

      //     maxDayTransactionCount = maxDayTransactionCount > timeList[key].count ? maxDayTransactionCount : timeList[key].count
      //   }
      // }
      legend_item = {
        name: charts[key]?.name,
        textStyle: {
          color: charts[key].color
        }
      }
      series_item = {
        show: false,
        name: charts[key]?.name,
        type: charts[key].type,
        yAxisIndex: charts[key].yAxisIndex,
        z: charts[key].z,
        data: dayTransactionCount,
        barWidth: '100%',
        clip: true,
        emphasis: {
          disabled: true // 禁用高亮
        },
        barGap: '-99%',
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(12, 196, 232, 1)'
            },
            {
              offset: 1,
              color: 'rgba(232, 252, 255, 0.3)'
            }
          ])
        },
        label: {
          show: true,
          fontSize: 14,
          color: 'rgba(103, 52, 194, 1)',
          position: 'top',
          formatter: function (data) {
            // 只取中间一个展示
            return ''
            const oData = data.data
            const curIndex = oData.index
            const middleIndex = Math.floor(oData.size / 2)

            if (curIndex === middleIndex) {
              return oData.value
            } else {
              return ''
            }
            // return ''
          }
        }
      }
    } else {
      legend_item = {
        name: charts[key]?.name,
        textStyle: {
          color: charts[key].color
        }
      }
      series_item = {
        name: charts[key]?.name,
        type: charts[key].type,
        yAxisIndex: charts[key].yAxisIndex,
        z: charts[key].z,
        smooth: false,
        showSymbol: false,
        // symbol: 'pin',
        // symbolSize: 6,
        lineStyle: { width: 2 },
        barWidth: 5,
        // areaStyle: {
        //   color: charts[key].color,
        //   opacity: 0.05
        // },
        emphasis: {
          disabled: true // 禁用高亮
        },
        data: charts[key].ylist
      }
    }
    if (selecetdKeys.value.indexOf(key) > -1) {
      option.color.push(charts[key].color)
      option.legend.data.push(legend_item)
      // option.legend.selected[charts[key]?.name] = charts[key].selected
      option.legend.selected[charts[key]?.name] = true
      option.legend.right = '20px'
      option.xAxis[0].data = charts[key].xlist
      // option.yAxis[3].max = maxDayTransactionCount
      option.series.push(series_item)
    }
  }
  console.log('option', option)
  // option.title.text = filterData.platform.text
  option && myChart.setOption(option)

  myChart.on('click', function (params) {
    console.log('params', params)
    if (params.componentSubType === 'bar') {
      const data = {
        curDate: params.data.curDate,
        curTime: params.data.groupId ? '' : params.data.curTime,
        platform: filterData.platform.value
      }

      console.log('params', params)

      emit('showDetails', data)
    }
  })
}
const localeValue = useI18n().locale.value
// K线图初始化
const periods = [
  {
    multiplier: 1,
    timespan: 'day',
    text: $t('日K')
  },
  {
    multiplier: 1,
    timespan: 'week',
    text: $t('周K')
  }
]
let myKLineChart = null
const echartProInit = () => {
  document.getElementById('J_Klinechart').innerHTML = ''
  const klineStorageObj = klineStorage.value ? JSON.parse(klineStorage.value) : {}
  myKLineChart = new KLineChartPro({
    container: 'J_Klinechart',
    // 水印
    watermark: '',
    // 标的
    symbol: {
      shortName: props.detail?.name,
      priceCurrency: 'cny',
      folderId: props.folder.folderId,
      platform: filterData.platform.value
    },
    locale: localeValue === 'zh' ? 'zh-CN' : 'en-US',
    // 主图指标
    mainIndicators: klineStorageObj?.mainIndicators || ['MA'],
    // 副图指标
    subIndicators: klineStorageObj?.subIndicators || ['VOL'],
    // 当前周期
    period: klineStorageObj?.period || { multiplier: 1, timespan: 'day', text: '日K' },
    // 所有周期
    periods: periods,
    // 数据接入api实现
    datafeed: new CustomDatafeed()
  })

  const red = '#F92855'
  const green = '#2DC08E'
  const alphaRed = 'rgba(249, 40, 85, .7)'
  const alphaGreen = 'rgba(45, 192, 142, .7)'
  myKLineChart.setStyles({
    candle: {
      bar: {
        upColor: red,
        downColor: green,
        upBorderColor: red,
        downBorderColor: green,
        upWickColor: red,
        downWickColor: green
      },
      priceMark: {
        last: {
          upColor: red,
          downColor: green
        }
      },
      tooltip: {
        showType: 'standard',
        custom: function () {
          // 这个必须得用方法才能生效，直接用对象没效果
          return [
            { title: 'time', value: '{time}' },
            { title: 'open', value: '{open}' },
            { title: 'high', value: '{high}' },
            { title: 'low', value: '{low}' },
            { title: 'close', value: '{close}' },
            { title: 'volume', value: '{volume}' }
          ]
        }
      }
    },
    indicator: {
      ohlc: {
        upColor: alphaRed,
        downColor: alphaGreen
      },
      bars: [
        {
          style: 'fill',
          borderStyle: 'solid',
          borderSize: 1,
          borderDashedValue: [2, 2],
          upColor: alphaRed,
          downColor: alphaGreen,
          noChangeColor: '#888888'
        }
      ],
      circles: [
        {
          style: 'fill',
          borderStyle: 'solid',
          borderSize: 1,
          borderDashedValue: [2, 2],
          upColor: alphaRed,
          downColor: alphaGreen,
          noChangeColor: '#888888'
        }
      ]
    }
  })
}

const klineClick = e => {
  const klineStorageObj = klineStorage.value ? JSON.parse(klineStorage.value) : {}
  const innerText = e.target.innerText
  const periodList = [$t('时K'), $t('日K'), $t('周K')]
  console.log('periodList', periodList)
  if (periodList.includes(innerText)) {
    klineStorage.value = JSON.stringify({
      mainIndicators: klineStorageObj.mainIndicators || ['MA'],
      subIndicators: klineStorageObj.subIndicators || ['VOL'],
      period: periods[periodList.indexOf(innerText)]
    })
  } else {
    const modal = document.querySelector('.klinecharts-pro-indicator-modal-list')
    if (!modal) return
    const rows = modal.querySelectorAll('li')
    if (!rows || (rows && rows.length === 0)) return

    const main = []
    const sub = []
    let isMainIndicator = true

    for (let i = 1; i < rows.length; i++) {
      // 跳过第一个，因为第一个一定是主图指标
      const row = rows[i]
      const className = row.className
      if (className === 'title') {
        // 此时变换到副图指标
        isMainIndicator = false
        continue
      }
      const checkboxDiv = row.querySelector('.klinecharts-pro-checkbox')
      if (checkboxDiv) {
        const label = checkboxDiv.querySelector('.label')?.textContent?.replace(/\(.*?\)/, '')
        if (checkboxDiv.classList.contains('checked') && label) {
          if (isMainIndicator) {
            main.push(label)
          } else {
            sub.push(label)
          }
        }
      }
    }
    klineStorage.value = JSON.stringify({
      mainIndicators: main,
      subIndicators: sub,
      period: klineStorageObj.period || { multiplier: 1, timespan: 'day', text: '日K' }
    })
  }
}

// 切换图标
const changeType = val => {
  console.log('val', val)
  if (val === 'charts') {
    getTendData()
  } else if (val === 'klinecharts') {
    echartProInit()
  }
}

// 切换类型
const changeTypePlatform = () => {
  if (filterData.showType === 'charts') {
    getTendData()
  } else if (filterData.showType === 'klinecharts') {
    echartProInit()
  }
}

// 切换日期
const changeTypeDay = () => {
  getTendData()
}

// 获取趋势分析数据
const getTendData = () => {
  const submitData = Object.assign(
    {},
    {
      platform: filterData.platform.value === 'all' ? null : filterData.platform.value,
      typeDay: filterData.typeDay.value,
      dateType: 3,
      folderId: props.folder.folderId
    },
    props.submitParam
  )

  filterData.charts = []
  loading.value = true
  collectFolderTrend(submitData)
    .then(res => {
      const temp = {
        data: {
          trendList: res.data
        }
      }
      handleTendData(temp)
    })
    .catch(() => {
      loading.value = false
    })
}

const handleTendData = res => {
  const oData = res.data
  console.log('oData', oData, props.usedBy)
  let trendData = {}
  if (props.usedBy === 'category') {
    trendData = oData
  } else {
    trendData.trendList = oData.trendList
  }
  // const trendData = props.usedBy === 'category' ? oData : oData

  loading.value = false
  trendData.trendList = trendData.trendList.sort((a, b) => {
    const aTime = dayjs(a.updateTime).valueOf()
    const bTime = dayjs(b.updateTime).valueOf()

    return aTime - bTime
  })
  oData.trendConfig = [
    {
      name: 'sellPrice',
      show: true,
      activate: true,
      forbidden: false
    },
    {
      name: 'sellCount',
      show: true,
      activate: true,
      forbidden: false
    },
    {
      name: 'biddingPrice',
      show: true,
      activate: false,
      forbidden: false
    },
    {
      name: 'biddingCount',
      show: true,
      activate: false,
      forbidden: false
    },
    {
      name: 'transactionPrice',
      show: true,
      activate: true,
      forbidden: false
    },
    {
      name: 'transactionCount',
      show: true,
      activate: true,
      forbidden: false
    },
    {
      name: 'dayTransactionCount',
      show: true,
      activate: true,
      forbidden: false
    }
  ]
  oData.trendConfig.forEach(config => {
    const curKey = config?.name

    if (curKey !== 'transactionPrice' && config.show) {
      filterData.charts[curKey] = {
        selected: config.activate,
        name: switchConfig(curKey)?.name,
        type: switchConfig(curKey).type,
        color: switchConfig(curKey).color,
        yAxisIndex: switchConfig(curKey).yAxisIndex,
        z: switchConfig(curKey).z,
        datelist: [],
        xlist: [],
        ylist: []
      }

      trendData.trendList.forEach(item => {
        const tempItem = {
          endTime: item[0],
          sellPrice: item[1],
          sellCount: item[2],
          biddingPrice: item[3],
          biddingCount: item[4],
          // transactionAmount: item[5],
          transactionCount: item[5]
        }
        filterData.charts[curKey].datelist.push(tempItem.endTime)
        filterData.charts[curKey].xlist.push(dayjs(tempItem.endTime * 1000).format('YYYY-MM-DD HH:mm:ss'))

        if (curKey === 'dayTransactionCount') {
          // 每日成交数量
          filterData.charts[curKey].ylist.push(tempItem['transactionCount'])
        } else {
          // 其他
          if (curKey === 'transactionCount') {
            // 成交数量
            filterData.charts[curKey].ylist.push({
              curDate: dayjs(tempItem.endTime * 1000).format('YYYY-MM-DD'),
              curTime: dayjs(tempItem.endTime * 1000).format('HH'),
              value: tempItem[curKey]
            })
          } else {
            filterData.charts[curKey].ylist.push(tempItem[curKey])
          }
        }

        // filterData.charts[curKey].ylist.push([dayjs(item.endTime * 1000).valueOf(), item[curKey]])
      })
    }
  })

  setTimeout(() => {
    if (filterData.showType === 'charts') {
      echartInit()
    } else {
      echartProInit()
    }
    // echartInit()
  })
}

// 类别信息读取
const switchConfig = key => {
  let curConfig = {}

  switch (key) {
    case 'sellPrice':
      curConfig = {
        name: $t('价格'),
        type: 'line',
        color: 'rgba(248, 118, 0, 0.86)',
        yAxisIndex: 0,
        z: 2
      }
      break
    case 'biddingPrice':
      curConfig = {
        name: $t('求购价'),
        type: 'line',
        color: 'rgba(255, 180, 0, 0.85)',
        yAxisIndex: 0,
        z: 2
      }
      break
    case 'sellCount':
      curConfig = {
        name: $t('在售数量'),
        type: 'line',
        color: 'rgba(0, 152, 255, 0.55)',
        yAxisIndex: 1,
        z: 2
      }
      break
    case 'biddingCount':
      curConfig = {
        name: $t('求购数量'),
        type: 'line',
        color: 'rgba(0, 200, 140, 0.55)',
        yAxisIndex: 2,
        z: 2
      }
      break
    case 'transactionCount':
      curConfig = {
        name: $t('小时成交量'),
        type: 'bar',
        color: '#6734C2',
        yAxisIndex: 3,
        z: 2
      }
      break
    case 'dayTransactionCount':
      curConfig = {
        name: $t('日成交量'),
        type: 'bar',
        yAxisIndex: 4,
        z: 1
      }
      break
  }

  return curConfig
}

defineExpose({ getTendData })
</script>

<style lang="scss" scoped>
@import url('~/assets/styles/_color.scss');

.detail-charts {
  .charts-menu {
    border-bottom: 1px solid #eff1f5;
    .filters {
      // padding-top: 20px;
    }
    :deep(.el-radio-group) {
      .el-radio {
        display: flex;
        justify-content: center;
        min-width: 60px;
        padding: 0 10px;
        margin: 0 auto;
      }
      .el-radio.is-bordered {
        border-color: transparent;
      }
      .el-radio.is-bordered.is-checked {
        background-color: $white-color;
        border-color: $primary-color;
      }

      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding: 0;
      }
    }
    :deep(.el-select) {
      .el-select__wrapper {
        border-radius: 10px;
        min-height: 40px;
      }
    }
    :deep(.platform-select) {
      .el-select__wrapper {
        border: none;
        box-shadow: none;
        .el-select__placeholder {
          position: relative;
          top: 12px;
        }
      }
    }
    :deep(.chart-types) {
      .el-tabs__header {
        margin: 0;
        height: 60px;
        .el-tabs__item {
          padding-top: 30px;
          padding-bottom: 30px;
        }
        .el-tabs__nav-wrap {
          &::after {
            height: 1px;
          }
        }
      }
    }
  }

  .charts-area {
    background: $white-color;
    position: relative;

    .area-bg {
      background: url('~/assets/images/detail/chart-bg.png') no-repeat;
      width: 1010px;
      height: 500px;
      margin: 50px auto 0;
      position: absolute;
      left: 0;
      right: 0;
    }

    #J_Echart {
      width: 980px;
      margin: 0 auto;
      height: 500px;
    }
  }
}
</style>

<style lang="scss">
.klinecharts-pro-period-bar .tools:nth-last-child(2) {
  /** 去除截图按钮 */
  display: none;
}

.klinecharts-pro-period-bar .tools:nth-last-child(4) {
  /** 去除时区按钮 */
  display: none;
}
</style>
